import React from "react";

import SEO from "../components/seo"
import GlobalStyle from './styles/global/global';

import Navbar from "../components/Navbar/Navbar";
import Apresentation from "./apresentation/index";
import Numbers from "./numbers/index";
import Anticipation from "./anticipation/index";
import Backoffice from "./backoffice/index";
import Video from "./video/index";
import Plan from "./plan/index";
import Company from "./company/index";
import Contact from "./contact/index";
import Summary from "./summary/index";
import Footer from "./footer/index";

const IndexPage = () => (
  <>
    <SEO title="Strategi Consultoria" />

    <Navbar />
    
    <Apresentation />
    
    <Numbers />

    <Anticipation />

    <Backoffice />

    <Video />

    {/* <Plan /> */}

    <Company />

    <Contact />

    <Summary />

    <Footer />

    <GlobalStyle />    

  </>
)

export default IndexPage
