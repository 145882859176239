import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
    background: #FFFF;
    display: flex;
    max-height: 579px;

    @media (max-width: 768px) {
        height: auto;
    }
`;

export const Boxes = styled.div`
    display: flex;
    flex-direction: row;
    
    align-items: center;
    justify-content: center;  

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const Box = styled.div`
    margin: auto 45px;

    @media (max-width: 768px) {
        padding-top: 30px;
        
    }
`;

export const BoxImage = styled.div`
  display: block;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const FormContract = styled.form`
    flex: 1;
    margin: auto;
    
    button {
        width: 165px;
        height: 45px;
        font-size: 13px;
        line-height: 21px;

        background: #80F5E4;
        border-radius: 100px;
        border: 0;
        color: #173746;
        
        transition: background-color 0.2s;

        &:hover {
        background: ${shade(0.2, '#85F5E4')};
        }
    }
`;

export const BoxDescription = styled.div`
    max-width: 280px;
    max-height: 146px;
    
    div {
        
        max-width: 97px;
        max-height: 26px;
        background: #FFFFFF;
        border: 1px solid #80F5E4;
        box-sizing: border-box;
        border-radius: 100px;
        flex: 1;
        align-items: center;
        text-align: center;

        h1 {
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 25px;
            letter-spacing: 0.08em;
            text-transform: uppercase;

            color: #173746;
        }
    }
    

    h2 {
        margin: 16px auto;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        color: #173746;
    }

    p {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #ADB5BD;

    }
`;

export const BoxDetail = styled.div`

    display: block;
    max-width: 200px;
    max-height: 90px;
    margin: 27px 0;
    
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        text-transform: none;

        li {

            font-family: Roboto;
            font-size: 14px;
            color: #6C757C;
            margin-top: 15px;
            
            ::before { 
                content: "●";
                font-weight: bold;
                margin-right: .7em; 
                text-align: center; 
                color: #80F5E4;
                display: inline-block; 
            }
        }
    }
`;