import React from "react"

import { FiTwitter, FiLinkedin, FiInstagram} from 'react-icons/fi'

import { Contact } from './styles';

const NavbarContact = () => {

    return (
        <>
            <Contact>
                <div>
                    <a href="https://www.instagram.com/strategiapp" target="_blank"><FiInstagram size={24} /></a>
                    
                    <a href="https://www.linkedin.com/company/strategi-consultoria" target="_blank"><FiLinkedin size={24} /></a>

                    <a href="https://twitter.com/explore" target="_blank"><FiTwitter size={24} /></a>
                </div>
            
                <p>Contato</p>
                <a target="_blank"
                    href="https://api.whatsapp.com/send?phone=5584991161258" >
                        +55 84 99116-1258
                </a> 
                <a target="_blank"
                    href="mailto:contato@strategi.in">
                    contato@strategi.in
                </a>
                
            </Contact>
        </>
    )
}

export default NavbarContact