import styled from 'styled-components';

export const Container = styled.section`
    background: #173746;
    display: flex;
    flex-flow: wrap;
`;

export const ContainerVideo = styled.div`
    display: block;
    margin: 72px auto;
    align-items: center;
    justify-content: center;
    margin-right: 8vh;
    
    h1 {
        text-align: center;
        
        width: 375px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 36px;
        text-transform: uppercase;
        color: #FFFFFF;
        
        margin: 0 auto auto auto;

        @media (max-width: 768px) {
            width: 237px;
            font-size: 24px;
            margin-bottom: 5vh;
            margin-top: 10vh;
            text-transform: none;
            font-weight: bold;
        } 
    }

    iframe {
        width:785px ;
        height:450px;
        margin-top: 31px; 

        @media (max-width: 768px) {
            width: 320px;
            height: 204.23px;
        }
    }

    @media (max-width: 768px) {
        margin: 40px auto;
    }
    
`;

export const Reasons = styled.div`
    display: block;
    align-items: left;
    justify-content: left;
    margin: auto auto;
    margin-left: 0px;
    padding-top:20vh;

    margin-bottom: 130px;

    flex-direction: column;

    @media (max-width: 768px) {
        margin-left: auto;
        padding-top: 5vh;
    }
`;


export const Reason = styled.div`
    color: #ffff;
    max-width: 318px;
    margin: 0 auto;
    
    & + div {
      margin-top: 32px;
    }

    h1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 40px;
        line-height: 40px;
        color: #80F5E4;
    }

    strong {
        padding-top: 5vh;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 29px;
        color: #FFFFFF;
        align-self: flex-start;
    }

    p{
        padding-top: 1vh;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        color: #ADB5BD;
        flex: none;
        order: 2;
        align-self: center;
        margin: 0px 4px;
    }
`;

export const Operational = styled.div`
    
    color: #ffff;
    
    margin: auto auto;
    align-items: center;
    justify-content: center;
    padding-top: 124px;
    display: flex;
    
    
    span {
        
        font-family: "Avenir", Avenir, Montserrat;;
        font-style: normal;
        font-weight: 900;
        font-size: 16px;
        line-height: 24px;
        text-transform: uppercase;
    }

    svg {
        color:#80F5E4;
        margin: auto auto;
    }

    @media (max-width: 768px) {
        display: none;
    }
`;
 
export const OperationalItems = styled.div`

    color: #ffff;
    display: flex;
    flex-direction: row;
    
    margin: auto auto;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    max-width: 187px;
    text-align: center;

    @media (max-width: 768px) {
        padding-top: 20vh;
        flex-flow: wrap;
    }
    
`;

export const OperationalItem = styled.div`
    color: #ffff;
    padding-bottom: 105px;
    margin: auto 55px;

    @media (max-width: 768px) {
        padding-bottom: 10vh;
        margin: 0 auto;
    }

    h1 {
        font-family: "Avenir", Avenir, Montserrat;;
        font-style: normal;
        font-weight: 900;
        font-size: 56px;
        line-height: 56px;
        color: #80F5E4;
    }

    p {
        font-family: "Avenir", Avenir, Montserrat;;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        color: #FFFFFF;
    }
`;