import styled from "styled-components"
import { shade } from 'polished';
import { Link } from "react-scroll";

export const ContainerNavigation = styled.section`
  margin: 0 auto;
  background: #173746;
  
`;

export const Navigation = styled.nav`
  max-width: 1440px;
  height: 130px;
  display: flex;
  background-color: #173746;
  position: relative;
  text-transform: uppercase;
  
  margin: 0 auto;
  padding: 0 5vw;
  z-index: 2;
  align-self: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    position: sticky;
    height: 110px;
    top: 0;
    left: 0;
    right: 0;
    left: 0;
  }
`;

export const LogoWrap = styled.div`
  margin: 20px 0 0 5vh;
  flex: 0 1 86px;

  @media (max-width: 768px) and (orientation: landscape) {
    flex: 0 1 25px;
    /* display: none; */
  }

  @media (max-width: 768px) and (orientation: portrait) {
    margin: 20px 0 0 2vh;
    flex: 0 1 60px;
  }
`;

export const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;
  padding: 0 10vw;

  @media (max-width: 768px) {
    display: flex;
  }
`;

export const Navbox = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  margin: auto 0px;

  @media (max-width: 768px) {
    
    /* margin: 4vh 0; */
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    padding-top: 1vh;
    background-color: #173746;
    transition: all 0.3s ease-in;
    height: 100%;
    left: ${props => (props.open ? "100%" : "0")};
    overflow-y: auto;
    padding-bottom: 60px;
  }
`;

export const Hamburger = styled.div`
  background-color: #80F5E4;
  width: 30px;
  height: 3px;
  transition: all .3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};
  
  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: #80F5E4;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }

  @media (max-width: 768px) {
    z-index: ${props => (props.open ? "6" : "0")};
    position: ${props => (props.open ? "fixed" : "absolute")};
  }
`;

export const NavItem = styled(Link)`
  text-decoration: none;
  color: #FFFFFF;
  font-weight:bold;
  
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;

  border-bottom: ${props => (props.open ? "1px solid rgba(255, 255, 255, .1)" : "none")};
  width: ${props => (props.open ? "100%" : "auto")};
  text-indent: ${props => (props.open ? "42px" : "0px")};
  
  cursor: pointer;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: #80F5E4;
    height: 1px;
    transition: all 0.4s ease-in;
  }

  :hover {
    color: #80F5E4;
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    font-size: 16px;
    z-index: 6;
    top: 15vh;
  }
`;

export const NavButtonItem = styled(Link)`
  text-decoration: none;
  color: #173746;
  font-weight:bold;
  
  white-space: nowrap;
  margin: 0 20px 0 140px;
  transition: all 200ms ease-in;
  position: relative;

  background: #80F5E4;
  border-radius: 100px;
  padding: 12px 40px;
  font-size: 13px;
  line-height: 25px;
  
  cursor: pointer;
  
  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    color: transparent;
    background: #80F5E4;
    height: 1px;
    transition: all 0.4s ease-in;
  }


  @media (max-width: 768px) {
    font-size: 16px;
    z-index: 1;
    margin: 5vh 0 auto 0px;
    font-weight:normal;
    height: 58px;
    width: 304px;
    font-size: 13px;
    text-align: center;
  }

  &:hover {
    background: ${shade(0.2, '#85F5E4')};
  }
`;
 
export const Contact = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto auto;
  text-align: center;
  text-transform: none;

  div {
    display: flex;
    justify-content: space-between;
    align-content: space-between;

    margin-top: 150px;
    svg {
      color: #80F5E4;
    }
  }

  p{
    padding-top: 30px; 
    font-family: "Avenir", Avenir, Montserrat;;
    font-weight: 900;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
  }

  a {
    font-family: "Avenir", Avenir, Montserrat;;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ADB5BD;
    text-transform: none;
  }
`;