import React from "react"

import { NavItem, NavButtonItem } from './styles';

import NavbarContact from "./NavbarContact"

class NavbarLinks extends React.Component {
  
  render() {
    return (
      <>
        <NavItem  
          to="antecipation"
          spy={true}
          smooth={true}
          duration= {700}
          onClick={this.props.callbackFromParent}
          open={this.props.state}
        > 
            Conheça a Solução
        </NavItem>

        <NavItem 
          to="video"
          spy={true}
          smooth={true}
          duration= {700}
          onClick={this.props.callbackFromParent}
          open={this.props.state}
        >
            Como Funciona
        </NavItem>

        <NavItem 
          to="company"
          spy={true}
          smooth={true}
          duration= {700}
          offset={-70}
          onClick={this.props.callbackFromParent}
          open={this.props.state}
        >
            
            Cases de Sucesso
        </NavItem>

        <NavItem 
          to="summary"
          spy={true}
          smooth={true}
          duration= {700}
          onClick={this.props.callbackFromParent}
          open={this.props.state}
        >
            Sobre a Strategi
        </NavItem>

        {this.props.state && <NavbarContact /> }

        <NavButtonItem 
          to="contact"
          spy={true}
          smooth={true}
          duration= {700}
          offset={-80}
          onClick={this.props.callbackFromParent}
        >
            Agendar Demonstração
        </NavButtonItem>
      </>
    )
  }
}

export default NavbarLinks