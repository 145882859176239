import React from 'react';
import { FiTrendingUp } from 'react-icons/fi';

import  { Container, 
          ContainerVideo,
          Reasons,
          Reason,
          Operational,
          OperationalItems,
          OperationalItem,
        } from './styles';

const Video = () => {

    return (
        <>
            <Container id="video" >
                <ContainerVideo>
                    <h1>Veja na prática nossa plataforma</h1>
                    <iframe
                        title="video-strategi-app" 
                        src="https://www.youtube.com/embed/fxPV9mb2bnw" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen>
                    </iframe>
                </ContainerVideo>

                <Reasons>
                    <Reason> 
                        <h1>1</h1>
                        <strong>Controle</strong>
                        <p>Sistema inteligente e totalmente transparente para fazer a o pagamento das suas comissões.</p>
                    </Reason>

                    <Reason> 
                        <h1>2</h1>
                        <strong>Antecipação</strong>
                        <p>Antecipe sua carteira com taxas especiais, e o dinheiro que levaria meses para ser recebido estará em suas mãos em até 48 horas.</p>
                    </Reason>

                    <Reason> 
                        <h1>3</h1>
                        <strong>Portal</strong>
                        <p>Sistema exclusivo de apoio à gestão de comissionamento para as imobiliárias.</p>
                    </Reason>
                </Reasons>
            </Container>
            
            
            {/*
            <Container>
                <Operational>
                 <span> Melhore sua Operação  &nbsp; </span>
                 <FiTrendingUp size={25}/>
                </Operational>
            </Container>

           
            <Container>
                <OperationalItems>

                    <OperationalItem>
                        <h1>+15%</h1>
                        <p>Melhor desempenho das equipes de vendas</p>
                    </OperationalItem>

                    <OperationalItem>
                        <h1>+24%</h1>
                        <p>Melhor desempenho das equipes de vendas</p>
                    </OperationalItem>

                    <OperationalItem>
                        <h1>-15%</h1>
                        <p>Custos operacionais administrativos</p>
                    </OperationalItem>

                    <OperationalItem>
                        <h1>+32%</h1>
                        <p>Melhor desempenho das equipes de vendas</p>
                    </OperationalItem>

                </OperationalItems>
            </Container>    
            */}
        </>
    )
}

export default Video