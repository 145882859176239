import React from 'react';

import {Container,
        FormContract,
        Boxes,
        Box,
        BoxDescription,
        BoxDetail,
        BoxImage,
    } from './styles';

import ImagePlan from '../../images/gatsby-plan-image.png';

const Plan = () => {
    return (
        <>  
        <Container>
            <FormContract>
                <Boxes>
                    <Box>
                        <BoxDescription>
                            <div> 
                                <h1>Plano</h1>
                            </div>
                            
                            <h2>Business</h2>
                            <p>BackOffice inovador integrando todos os setores de forma ágil e fácil.</p>
                        </BoxDescription>
                        <BoxDetail>
                            <ul>
                                <li>Até 10 vendedores</li>
                                <li>Antecipação</li>
                                <li>Help Desk</li>
                            </ul>
                        </BoxDetail>
                        
                        <button>Contratar</button>
                    </Box>

                    <Box>
                        <BoxDescription>
                            <div> 
                                <h1>Plano</h1>
                            </div>
                            
                            <h2>Enterprise</h2>
                            <p>BackOffice inovador integrando todos os setores de forma ágil e fácil.</p>
                        </BoxDescription>
                        <BoxDetail>
                            <ul>
                                <li>Até 20 vendedores</li>
                                <li>Antecipação</li>
                                <li>Help Desk</li>
                            </ul>
                        </BoxDetail>
                        
                        <button>Contratar</button>
                    </Box>
                </Boxes>

            </FormContract>

            <BoxImage>
                <img src={ImagePlan} />
            </BoxImage>
        </Container>
        
        </>
    )
}

export default Plan