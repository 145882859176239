import React, { useState } from "react"
import NavbarLinks from "./NavbarLinks"
import Logo from "./Logo"

import { ContainerNavigation,
         Navigation,  
         Toggle, 
         Hamburger, 
         Navbox
       } from './styles';


class Navbar extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        navbarOpen: false
      };    
  }

  changeNavbarStatus = () => {
    this.setState({navbarOpen: !this.state.navbarOpen})
  }

  changeNavbarStatusFromLink = () => {

    if (this.state.navbarOpen){
      this.setState({navbarOpen: !this.state.navbarOpen})
    }
  }

  render() {
    return (
      <ContainerNavigation>

        <Navigation>
          
          {!this.state.navbarOpen && <Logo />} 

          <Toggle
            navbarOpen={this.state.navbarOpen}
            onClick={this.changeNavbarStatus}
          >
            {this.state.navbarOpen ? <Hamburger open /> : <Hamburger />}
          </Toggle>
          {this.state.navbarOpen ? (
            <Navbox>
              <NavbarLinks state={this.state.navbarOpen} callbackFromParent={this.changeNavbarStatusFromLink} />
            </Navbox>
          ) : (
            <Navbox open>
              <NavbarLinks state={this.state.navbarOpen} callbackFromParent={this.changeNavbarStatusFromLink}/>
            </Navbox>
          )}
        </Navigation>
        
      </ContainerNavigation>
    )
  }
}

export default Navbar